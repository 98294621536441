import React from 'react'

const Dog = () => {
  return (
    <div>
     
      <section className='page-top'>
        <h1>Solo</h1>
      </section>
      <section className='dogs-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/solosten_mid.png')} alt="Solo sitter på en sten" />
          </div>
          <div className='facts'>
            <h2>Fakta om Solo</h2>
            <ul>
              <li>Bohlsta Trollets Lady</li>
              <li>Född 2018-08-09</li>
              <li>SE50458/2018</li>
              <li>HD A/B</li>
              <li>ED ua</li>
              <li>Öga ua</li>
              <li>BHP med skott</li>
              <li><a href="http://www.rasdata.nu/labrador/hund/2018/s5045818.htm" target='_blank' rel="noreferrer">Länk till Rasdata</a></li>
            </ul>
            <p>Solo är en underbar foxröd Labrador med ett hjärta av guld. Hon älskar att träna och lära sig nya saker.</p>
            <p>Det bästa hon vet är gurka, morot och äpplen.</p>
          </div>
        </div>
        
      </section>
      <section className='dogs-gallery container'>
        <h2>Bilder på Solo</h2>
        <div className='dogs-images'>
          <div>
            <img src={require('../images/solo_valp.jpg')} alt="Solo som valp, 8 veckor." />
          </div>
          <div>
            <img src={require('../images/valp_solo.jpg')} alt="Solo som valp, 4 månader." />
          </div>
          <div>
            <img src={require('../images/solo_34.png')} alt="Solo busar med en boll." />
          </div>
          <div>
            <img src={require('../images/solo_sol.jpg')} alt="Solo sitter på gräset i solen." />
          </div>
          <div>
            <img src={require('../images/bph.jpg')} alt="Solo med sin BPH-rosett." />
          </div>
          <div>
            <img src={require('../images/solo_hall.jpg')} alt="Solo sitter i hallen efter sin dejt med Pluto." />
          </div>
          <div>
            <img src={require('../images/solo_leksaker.jpg')} alt="Solo med två leksaker i munnen." />
          </div>
          <div>
            <img src={require('../images/solo_vinter.jpg')} alt="Solo ute i snön." />
          </div>
          <div>
            <img src={require('../images/solo_sitter.jpg')} alt="Solo sitter i köket, dräktig." />
          </div>
        </div>
      </section>
    </div>
  )
}

export default Dog