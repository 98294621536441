import React from 'react'
import Banner from '../components/Banner'

const Connect = () => {
  return (
    <div id='connect'>
      <section className='page-top'>
        <h1>Kontakta oss</h1>
      </section>
      <section className='connect-content'>
        <div className='container'>
          <div className='connect-side'>
            <img src={require('../images/solovalp_small.jpg')} alt="Solo som valp." />
          </div>
          <div className='connect-side'>
            <h2>Valpar</h2>
            <h3>Just nu har vi inga valpar till salu.<br/>Nästa valpkull planeras tidigast 2026.</h3>
            {/* <p>Valparna kan tidigast flytta hemifrån när de är 8 veckor gamla, dvs 22/6-23.</p>
            <p>Valparna kommer självklart att vara vaccinerade, avmaskade, chippade, besiktigade av veterinär och registrerade i Svenska Kennelklubben. Vi har även tecknat en dolda-fel-försäkring hos Agria.</p>
            <p>Vi är måna om att våra valpar kommer till bra hem och vi vill därför träffa er, precis som ni vill träffa valpen, innan vi lovar något. Vi har inget kösystem och att få komma och besöka valparna är ingen garanti för att man får köpa en valp.</p>
            <p>Priset för en valp är 22 000 kr.</p>
            <p>Om du är intresserad vill vi gärna att du hör av dig via <a className='mail' href='mailto:majtbeekennel@gmail.com'>mail</a> och berättar lite om dig/er.</p>
            <p>Du kan även följa oss på <a href="https://www.instagram.com/majtbeekennel/" target='_blank' rel="noreferrer">Instagram</a> eller <a href="https://www.facebook.com/people/MajtBee-Kennel/100091884261077/" target='_blank' rel="noreferrer">FaceBook</a></p> */}
          </div>
        </div>
      </section>
      <Banner />
    </div>
  )
}

export default Connect