import React from 'react'
import { NavLink } from 'react-router-dom'
import Banner from '../components/Banner'

const Home = () => {
  return (
    <div id='home'>
      <section className='page-top'>
        <h1>Välkommen till MajtBee!</h1>
        <p></p>
      </section>
      <section className='home-content'> 
        <div className='card'>
          <NavLink to={'/dogs'}>
            <div className='section-link'>
              <div className='card-img-container'>
                <img src={require('../images/skillnad2.png')} alt="Kola och Solo står i dörröppningen." />
              </div>
              <div className='section-text'>
                <h2>Kola och Solo</h2>
                <p>Kola har hunnit bli stor tjej, även om hon verkar bli en liten labrador. Här ser man tydligt storleksskillnaden på Solo och Kola.</p>
                <p>Nu har Solo och Kola fått varsin sida med mer information och bilder.</p>
              </div>
            </div>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink to={'/puppies'}>
            <div className='section-link'>
              <div className='card-img-container'>
                <img src={require('../images/valptraff.JPG')} alt="Solo, Mentos och Kola ligger i gräset." />
              </div>
              <div className='section-text'>
                <h2>Valpträff</h2>
                <p>I början av maj 2024 hade vi en valpträff och fick träffa alla Solos fina ettåringar.</p>
              </div>
            </div>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink to={'/puppies'}>
            <div className='section-link'>
              <div className='card-img-container'>
                <img src={require('../images/solo_valphog.jpg')} alt="Solo ligger i gräset med en hög valpar på sig." />
              </div>
              <div className='section-text'>
                <h2>Valparna har flyttat</h2>
                <p>Solos valpar har flyttat till sina nya familjer.</p>
                <p>Kola stannar kvar hemma hos oss.</p>
                <p>Vi har ingen planerad kull i dagsläget. Nästa valpkull planeras tidigast 2026.</p>
              </div>
            </div>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink to={'/puppies'}>
            <div className='section-link'>
              <div className='card-img-container'>
                <img src={require('../images/solo_rollo.jpg')} alt="Solo och Rollo på baksidan." />
              </div>
              <div className='section-text'>
                <h2>Solos underbara valpar</h2>
                <p>Solos valpar växer och utvecklas för fullt.</p>
                <p></p>
                <p>Här kan du se fler bilder och följa hur det går för Solos valpar.</p>
              </div>
            </div>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink to={'/dogs'}>
            <div className='section-link'>
              <div className='card-img-container'>
                <img src={require('../images/soloskog_small.png')} alt="Solo i skogen" />
              </div>
              <div className='section-text'>
                <h2>Solo</h2>
                <p>Solo är världens finaste och snällaste foxröda labrador.</p>
                <p>Här kan du läsa mer om Solo.</p>
              </div>
            </div>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink to={'/puppies'}>
            <div className='section-link'>
              <div className='card-img-container'>
                <img src={require('../images/solo_valpar.jpg')} alt="Labradoren Solo ligger och diar sina valpar." />
              </div>
              <div className='section-text'>
                <h2>Solo har fått valpar!</h2>
                <p>Den 27 april föddes 6 fina labradorvalpar, 3 tikar och 3 hanar!</p>
                <p>Solo har varit jätteduktig och tar hand om sina valpar för fullt.</p>
              </div>
            </div>
          </NavLink>
        </div>
      </section>
      <Banner />
    </div>
  )
}

export default Home