import React, { useState } from 'react'
// import { NavLink } from 'react-router-dom'
import DogItem from '../components/DogItem'

const Dogs = () => {

  const [doggies] = useState([
    {
      id: "Solo",
      name: "Solo",
      image: "solo_i_kvadrat",
      alt: "Solo sitter i köket."
    },
    {
      id: "Kola",
      name: "Kola",
      image: "kola_i_kvadrat",
      alt: "Kola sitter i köket."
    }
  ])


  return (
    <div id='dogs'>
      <section className='page-top'>
        <h1>Våra hundar</h1>
      </section>
      <section className='dogs-content'>
        <div className='container'>

          {
            doggies.length ?
              doggies.map(doggie => (
                <DogItem key={doggie.id} doggie={doggie} />
              ))
              : <p>No dogs found</p>
          }

          {/* <NavLink to={'/dog'}>
            <div className='dog-card'>
              <div className='top-img'>
                <img src={require('../images/solo_i_kvadrat.JPG')} alt="Solo sitter i köket." />
              </div>
              <h2>Solo</h2>
            </div>
          </NavLink>
          <NavLink to={'/dog'}>
            <div className='dog-card'>
              <div className='top-img'>
                <img src={require('../images/kola_i_kvadrat.JPG')} alt="Kola sitter i köket." />
              </div>
              <h2>Kola</h2>
            </div>
          </NavLink> */}
        </div>
        
      </section>
      
    </div>
  )
}

export default Dogs