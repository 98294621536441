import React from 'react'

const Kola = () => {
  return (
    <div>
     <section className='page-top'>
        <h1>Kola</h1>
      </section>
      <section className='dogs-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/kola_i_kvadrat.JPG')} alt="Kola sitter i köket." />
          </div>
          <div className='facts'>
            <h2>Fakta om Kola</h2>
            <ul>
              <li>MajtBee Kola</li>
              <li>Född 2023-04-27</li>
              <li>SE27732/2023</li>
              {/* <li>HD A/B</li> */}
              {/* <li>ED ua</li> */}
              {/* <li>Öga ua</li> */}
              {/* <li>BHP med skott</li> */}
              <li><a href="https://rasdata.nu/labrador/hund/2020/s2773223.htm" target='_blank' rel="noreferrer">Länk till Rasdata</a></li>
            </ul>
            <p>Kola är en härlig foxröd Labrador full av bus och nyfikenhet. Hon är väldigt bra på att lyssna trots att hon är en unghund och älskar att lära sig nya saker.</p>
            <p>Det bästa hon vet är gurka, morot och äpplen, precis som hennes mamma.</p>
          </div>
        </div>
        
      </section>
      <section className='dogs-gallery container'>
        <h2>Bilder på Kola</h2>
        <div className='dogs-images'>
          <div>
            <img src={require('../images/kola_valp.jpg')} alt="Kola som valp, 4 veckor." />
          </div>
          <div>
            <img src={require('../images/kola_sover.jpg')} alt="Kola sover, 2 månader." />
          </div>
          <div>
            <img src={require('../images/kola_knas.png')} alt="Kola gör en konstig min, 2,5 månader." />
          </div>
          <div>
            <img src={require('../images/kola_gras.jpg')} alt="Solo sitter på gräset." />
          </div>
          <div>
            <img src={require('../images/kola_sten.jpg')} alt="Solo sitter på en sten." />
          </div>
          <div>
            <img src={require('../images/kola_host.jpg')} alt="Kola sitter bland höstlöven." />
          </div>
          <div>
            <img src={require('../images/kola_i_snon.JPG')} alt="Kola ute i snön." />
          </div>
          <div>
            <img src={require('../images/kola_stolt.JPG')} alt="Kola sitter stolt." />
          </div>
          <div>
            <img src={require('../images/kola_i_graset.JPG')} alt="Kola ligger i gräset." />
          </div>
        </div>
      </section>
    </div>
  )
}

export default Kola