import React from 'react'
import { NavLink } from 'react-router-dom'

const DogItem = ({ doggie }) => {

  return (
    <div>
      <NavLink to={`/dogs/${doggie.id}`}>
        <div className='dog-card'>
          <div className='top-img'>
            <img src={require('../images/' + doggie.image + '.JPG')} alt={doggie.alt} />
          </div>
          <h2 className='dog-name'>{doggie.name}</h2>
        </div>
      </NavLink>
    </div>
  )
}

export default DogItem