import React from 'react'
import Banner from '../components/Banner'

const About = () => {
  return (
    <div id='about'>
      <section className='page-top'>
        <h1>Om MajtBee</h1>
        <p></p>
      </section>
      <section className='about-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/maja_solo.jpg')} alt="Solo sitter på en sten" />
          </div>
          <div className='about-content-text'>
            <p>Vi som har MajtBee heter Maja och Roger och bor i Skultuna utanför Västerås. Vi har två underbara labradorer. Solo är vår stora pälskling, som förgyllt våra dagar och som fungerat lite som en assistanshund till flera i familjen. Kola är vår lilla pälskling, som är Solos valp från Godiskullen. Kola är ett litet busfrö fortfarande, men väldigt lättlärd och lyssnar väldigt bra.</p>
            <p>Maja är den som kommit på kennelnamnet och även den som är ägare till uppfödningen. Namnet MajtBee kommer från en rad olika smeknamn som Maja haft under åren, bl.a. Maiju, Majuutschi och Maja the Bee, och som sedan i en kombination tillslut blev MajtBee.</p>
            <p>Målet är att föda upp friska samarbetsvilliga Labradorer i liten skala i hemmamiljö. Självklart följer vi Svenska Kennelklubbens regler.</p>
          </div>
        </div>
      </section>
      <Banner />
    </div>
  )
}

export default About