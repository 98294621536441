import React from 'react'
import Banner from '../components/Banner'
import { Link } from 'react-router-dom'

const Puppies = () => {
  return (
    <div id='puppies'>
      <section className='page-top'>
        <h1>Valpar</h1>
      </section>
      <section className='puppies-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/valptraff.JPG')} alt="Solo, Mentos och Kola ligger i gräset." />
          </div>
          <div className='puppies-text'>
            <small>2024-05-04</small>
            <h2>Godiskullens valpträff</h2>
            <p>I början av maj 2024 hade vi en valpträff och fick träffa alla Solos fina ettåringar. Det blev full fart och mycket lek.</p>
            <div className='puppies-img-y-many'>
              <div className='img-container'>
                <img src={require('../images/valptraff2.png')} alt="Rollo, Solo, Kola och Mentos sitter i gräset med Roger och Maja." />
              </div>
              <div className='img-container'>
                <img src={require('../images/valptraff3.png')} alt="Fem av Solos valpar leker för fullt." />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='puppies-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/solo_valphog.jpg')} alt="Solo och Kola sitter i köket." />
          </div>
          <div className='puppies-text'>
            <small>2023-06-30</small>
            <h2>Valparna har flyttat</h2>
            <p>Nu har alla valparna flyttat till sina nya familjer.</p>
            <p>Kola stannar kvar hos oss och.</p>
            <div className='puppies-img-many'>
              <div className='img-container'>
                <img src={require('../images/kola_sover.jpg')} alt="Kola ligger och sover i en bädd." />
              </div>
              <div className='img-container'>
                <img src={require('../images/solo_kola.jpg')} alt="Solo ligger i gräset med en hög valpar på sig." />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='puppies-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/babygym.jpg')} alt="Labradoren Solo busar med sina valpar." />
          </div>
          <div className='puppies-text'>
            <small>2023-06-15</small>
            <h2>Valparna är nu 7 veckor!</h2>
            <p>Valparna har hunnit bli 7 veckor gamla och de senaste veckorna har fyllts med massa bus, utforskande och besök av valparnas nya familjer.</p>
            <p>Igår fick valparna doppa tassarna i hundpoolen för första gången och alla ville in i poolen igen.</p>
            <div className='puppies-img-one'>
              <div className='img-container'>
                <img src={require('../images/poolbus.jpg')} alt="Några valpar sover i en hög." />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='puppies-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/solo_busar.jpg')} alt="Labradoren Solo busar med sina valpar." />
          </div>
          <div className='puppies-text'>
            <small>2023-05-27</small>
            <h2>Valparna 1 månad!</h2>
            <p>Nu har valparna blivit 1 månad gamla och fått vara utomhus första gångerna.</p>
            <p>Det har blivit mer aktivitet i valphagen och valparna vill gärna komma utanför hagen för att gosa i famnen på någon i familjen.</p>
            <div className='puppies-img-one'>
              <div className='img-container'>
                <img src={require('../images/dumle_ute.jpg')} alt="Några valpar sover i en hög." />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='puppies-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/matstund.jpg')} alt="Labradorvalparna ligger och sover." />
          </div>
          <div className='puppies-text'>
            <small>2023-05-25</small>
            <h2>Presentation av Godiskullen</h2>
            <p>Här kommer en presentation av valparna i Godiskullen:</p>
            <div className='puppies-img-many'>
              <div className='img-container'>
                <img src={require('../images/dumle.jpg')} alt="Labradorvalp, hane." />
                <p>Blå - Dumle <i className="fa-solid fa-mars"></i></p>
              </div>
              <div className='img-container'>
                <img src={require('../images/twix.jpg')} alt="Labradorvalp, hane." />
                <p>Orange - Twix <i className="fa-solid fa-mars"></i></p>
              </div>
              <div className='img-container'>
                <img src={require('../images/fruxo.jpg')} alt="Labradorvalp, hane." />
                <p>Grön - Fruxo <i className="fa-solid fa-mars"></i></p>
              </div>
              <div className='img-container'>
                <img src={require('../images/rollo.jpg')} alt="Labradorvalp, tik." />
                <p>Röd - Rollo <i className="fa-solid fa-venus"></i></p>
              </div>
              <div className='img-container'>
                <img src={require('../images/mentos.jpg')} alt="Labradorvalp, tik." />
                <p>Svart - Mentos <i className="fa-solid fa-venus"></i></p>
              </div>
              <div className='img-container'>
                <img src={require('../images/kola.jpg')} alt="Labradorvalp, tik." />
                <p>Lila - Kola <i className="fa-solid fa-venus"></i></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='puppies-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/trotta_valpar.jpg')} alt="Labradorvalparna ligger och sover." />
          </div>
          <div className='puppies-text'>
            <small>2023-05-21</small>
            <h2>Valparna = Godiskullen</h2>
            <p>Nu har vi äntligen bestämt namn på valparna, kullen blev alltså Godiskullen. Idag har valparna blivit registrerade på SKK och kommer annonseras inom kort på SKKs Köpa hund-sida.</p>
            <p>Valparna har för några dagar sedan fått större utrymme då de fick flytta in i valphagen. De har även fått börja smaka på mat.</p>
            <div className='puppies-img-gallery'>
              <div className='img-container landscape'>
                <img src={require('../images/valpar_sover_i_hog.jpg')} alt="Några valpar sover i en hög." />
              </div>
              <div className='img-container landscape'>
                <img src={require('../images/valpar_matstund.jpg')} alt="Valparna smakar på mat." />
              </div>
              <div className='img-container portrait'>
                <img src={require('../images/sovande_valpar.jpg')} alt="Valparna sover på rad." />
              </div>
              <div className='img-container portrait'>
                <img src={require('../images/valpar_smakis.jpg')} alt="Valparna smakar på mat." />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='puppies-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/valpar_3veckor.jpg')} alt="En labradorvalp sitter medan en annan valp gäspar." />
          </div>
          <div className='puppies-text'>
            <small>2023-05-18</small>
            <h2>Solos valpar 3 veckor!</h2>
            <p>Solos fina valpar är nu 3 veckor och tränar för fullt på att sitta.</p>
            <p>De har öppnat sina ögon, börjat höra och har fullt upp med att utforska världen.</p>
            <p>Är du intresserad av att köpa en valp? <Link to={'/connect'}>Kontakta oss med din intresseanmälan så återkommer vi.</Link></p>
          </div>
        </div>
      </section>
      <section className='puppies-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/valpar_2veckor.jpg')} alt="Labradorvalparna ligger och sover." />
          </div>
          <div className='puppies-text'>
            <small>2023-05-11</small>
            <h2>Solos valpar är nu 2 veckor gamla!</h2>
            <p>Solos söta valpar har nu fyllt 2 veckor och börjat ta sina första steg.</p>
            <p>Nu väntar vi med spänning på att de ska öppna sina ögon och börja utforska världen.</p>
            <p>Är du intresserad av att köpa en valp? <Link to={'/connect'}>Kontakta oss med din intresseanmälan så återkommer vi.</Link></p>
          </div>
        </div>
      </section>
      <section className='puppies-content'>
        <div className='container'>
          <div className='top-img'>
            <img src={require('../images/solo_valpar.jpg')} alt="Labradoren Solo ligger och diar sina valpar." />
          </div>
          <div className='puppies-text'>
            <small>2023-04-27</small>
            <h2>Solo har fått valpar!</h2>
            <p>Den 27 april föddes 6 fina labradorvalpar, 3 tikar och 3 hanar!</p>
            <p>Solo har varit jätteduktig och tar hand om sina valpar för fullt.</p>
            <div className='puppies-img-gallery'>
              <div className='img-container landscape'>
                <img src={require('../images/labradorvalpar.jpg')} alt="Nyfödda labradorvalpar." />
              </div>
              <div className='img-container landscape'>
                <img src={require('../images/nyfodda_liggande.jpg')} alt="Nyfödda labradorvalpar i närbild." />
              </div>
              <div className='img-container portrait'>
                <img src={require('../images/forsta_valpen.jpg')} alt="Den första nyfödda labradorvalpen." />
              </div>
              <div className='img-container portrait'>
                <img src={require('../images/nyfodda.jpg')} alt="Nyfödda labradorvalpar i närbild." />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='puppies-content'>
      <div className='container'>
          <div className='top-img'>
            <img src={require('../images/solo_draktig_small.jpg')} alt="Solo står ute, dräktig." />
          </div>
          <div className='puppies-text'>
            <small>2023-04-11</small>
            <h2>Solo ska få valpar!</h2>
            <p>I slutet av februari var Solo på dejt hos <a href="https://kennelboholmens.se/447145751/447152788" target='_blank' rel="noreferrer"> Pluto på Boholmens kennel</a> och det blev en lyckad parning.</p>
            <p>Beräknad födsel för valparna är valborgshelgen.</p>
          </div>
        </div>
      </section>
      <Banner />
    </div>
  )
}

export default Puppies