import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD1yVhuQ0LJ9Lp6SrfKeTzps0DlV3C6oNY",
  authDomain: "majtbee-8f808.firebaseapp.com",
  projectId: "majtbee-8f808",
  storageBucket: "majtbee-8f808.appspot.com",
  messagingSenderId: "496789270054",
  appId: "1:496789270054:web:786cc6c2f8f6dd49fe80e7",
  measurementId: "G-Z6HF7SYLHB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_recieved');


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
