import React from 'react'

const Banner = () => {
  return (
    <section className='banner'>
      <a href="https://www.skk.se/sv/hundagande/kopa-hund/Nar-du-bestamt-dig/uppfodare/varfor-ska-jag-valja-en-skk-uppfodare/" target='_blank' rel="noreferrer">
        <div>
        <img src={require('../images/webbannons-sjalvklart-vit-880x290px.png')} alt="Webb banner för Svenska Kennelklubben." />
        </div>
      </a>
    </section>
  )
}

export default Banner