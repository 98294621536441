import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import './styles/App.scss';
import About from './views/About';
import Connect from './views/Connect';
import Dogs from './views/Dogs';
import Home from './views/Home';
import Puppies from './views/Puppies';
import NotFound from './views/NotFound';
import Dog from './components/Dog';
import Kola from './components/Kola';

const App = () => (
  <Router>
  <div id="App">
    <NavBar />
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/connect' element={<Connect />} />
      <Route path='/dogs' element={<Dogs />} />
      <Route path='/dogs/Solo' element={<Dog />} />
      <Route path='/dogs/Kola' element={<Kola />} />
      <Route path='/puppies' element={<Puppies />} />
      <Route path='*' element={<NotFound />} /> 
    </Routes>
    <Footer />
  </div>
  </Router>
)

export default App;
