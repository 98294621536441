import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <footer id='footer'>
      <section>
        <div className='footer-left'>
          <NavLink className='logo-footer' to={'/'}>MajtBee</NavLink>
          <div className='social'>
            <a href="https://www.instagram.com/majtbeekennel/" aria-label='Följ MajtBee på Instagram.' target='_blank' rel="noreferrer"><i className='fa-brands fa-instagram'></i></a>
            <a href="https://www.facebook.com/people/MajtBee-Kennel/100091884261077/" aria-label='Följ MajtBee på Facebook.' target='_blank' rel="noreferrer"><i className='fa-brands fa-facebook-f'></i></a>
            <a className='mail' href='mailto:majtbeekennel@gmail.com' aria-label='Skicka mail till MajtBee.'><i className='fa-regular fa-envelope'></i></a>
          </div>
        </div>
        <ul>
          <li><NavLink to={'/about'}>Om MajtBee</NavLink></li>
          <li><NavLink to={'/dogs'}>Våra hundar</NavLink></li>
          <li><NavLink to={'/puppies'}>Valpar</NavLink></li>
          <li><NavLink to={'/connect'}>Kontakt</NavLink></li>
        </ul>  
      </section>
    </footer>
  )
}

export default Footer