import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div>
      <section className='page-top'>
        <h1>Hoppsan! Sidan finns inte.</h1>
      </section>
      <section className='notfound'>
        <div className='container'>
          <h2>Du kanske letade efter någon av följande sidor:</h2>
          <ul>
            <li><Link to={'/Home'}>Hem</Link></li>
            <li><Link to={'/About'}>Om MajtBee</Link></li>
            <li><Link to={'/Dogs'}>Solo</Link></li>
            <li><Link to={'/Puppies'}>Valpar</Link></li>
            <li><Link to={'/Connect'}>Kontakt</Link></li>
          </ul>
          
        </div>
        
      </section>
    </div>
  )
}

export default NotFound